import React, { useEffect } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import RequestDemoBannerSection from "./RequestDemoBannerSection";
import Helmet from "helmet";

const RequestDemo = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Container fluid="fluid">
        <Row>
          <Col className="p-0">
            <RequestDemoBannerSection />
            <div className="request_imputs">
              <div className="title">
                Unlock the potential of ESG compliance for your business.
              </div>
              <Container>
                <p className="my-4">Personal Information</p>
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="mb-4"
                    controlId="validationCustom01"
                  >
                    <Form.Label>
                      <b>First Name</b>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="First Name"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="mb-4"
                    controlId="validationCustom01"
                  >
                    <Form.Label>
                      <b>Last Name</b>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Last Name"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="mb-4"
                    controlId="validationCustom01"
                  >
                    <Form.Label>
                      <b>Phone Number</b>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Phone Number"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="mb-4"
                    controlId="validationCustom01"
                  >
                    <Form.Label>
                      <b>Email Address</b>
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Email Address"
                    />
                  </Form.Group>
                </Row>
                <p className="my-4">Company Information</p>
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="mb-4"
                    controlId="validationCustom01"
                  >
                    <Form.Label>
                      <b>Company Name</b>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Company Name"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    className="mb-4"
                    controlId="validationCustom01"
                  >
                    <Form.Label>
                      <b>Job Title</b>
                    </Form.Label>
                    <Form.Select aria-label="Default select example">
                      <option>Select Job Title</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <p
                  className="mt-4 text-center"
                  style={{ marginBottom: "-30px" }}
                >
                  <b>
                    <u>Preferred Demo Date and Time</u>
                  </b>
                </p>
                <div
                  className="calendly-inline-widget"
                  data-url="https://calendly.com/roop-b98/price-review?hide_landing_page_details=1&hide_gdpr_banner=1&primary_color=00b4f1"
                  style={{ width: "100%", height: 700 }}
                ></div>
                <Form.Group
                  as={Col}
                  md="12"
                  className="mb-4"
                  controlId="validationCustom01"
                >
                  <Form.Label>
                    <b>Additional Comments or Questions</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Write your comments or questions here..."
                  />
                </Form.Group>
                <div className="text-center mb-4">
                  <Button
                    variant="danger"
                    type="submit"
                    className="button-block w-50"
                  >
                    Submit
                  </Button>
                </div>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RequestDemo;
