import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CarauselPage from '../Services/Carousal'
import './projectPage.css'

const SustainabilityPage = () => {
  return (
    <div>
      <div className="sustainbanerSection">
        <div className="banertextgroup banneroverlay">
          <h1>Sustainability Frameworks</h1>
        </div>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="sustain_123 mt-4">
              <h5>ECOSYSTEM</h5>
              <p>
                {' '}
                ESG reporting involves the systematic disclosure of data
                pertaining to the environmental, social, and governance impacts
                of business operations of not only a firm but the entire supply
                chain. Besides regulatory disclosure mandates, reporting has
                also become critical in aligning sentiments and building the
                trust of stakeholders such as vendors, consumers, and investors.
                The ESG performance of a business has a direct influence on its
                market reception, employee engagement, investment attraction,
                etc.
              </p>
              <p>
                A range of potential risks may impact your business, which would
                determine the scope of reporting for your organization.
              </p>
              <Container className="size">
                <Row className="mb-4">
                  <Col
                    md={5}
                    lg={5}
                    sm={6}
                    className="card-sustain custom-margin-lg"
                  >
                    <h3 className="text-center">
                      <strong>Governance Risks</strong>
                    </h3>
                    <ul className="mt-3">
                      <li>Evolving Regulatory Requirements</li>
                      <li>Crisis Management Risks</li>
                      <li>Systemic Risks</li>
                      <li>Risks related to Ethics at Workplace</li>
                    </ul>
                  </Col>
                  <Col md={1}></Col>{' '}
                  {/* Add Bootstrap spacing class here, e.g., "mb-3" */}
                  <Col md={5} lg={5} sm={6} className="card-sustain">
                    <h3 className="text-center">
                      <strong>Supply Chain Risks</strong>
                    </h3>
                    <ul className="mt-3">
                      <li>Raw Material Sourcing Risks</li>
                      <li>Vendor Relation Risks</li>
                      <li>Price Volatility</li>
                      <li>Disruptions in Plant Operations</li>
                      <li>Distribution Impediments</li>
                    </ul>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col
                    md={5}
                    lg={5}
                    sm={6}
                    className="card-sustain custom-margin-lg"
                  >
                    <h3 className="text-center">
                      <strong>Environment Risks</strong>
                    </h3>
                    <ul className="mt-3">
                      <li>Direct and Indirect GHG Emissions</li>
                      <li>Pollution</li>
                      <li>Toxic Waste Generation</li>
                      <li>
                        Risks related to Water Availability, Usage, and
                        Recycling
                      </li>
                      <li>Energy Management Risks</li>
                      <li>Weather Extremities and Climate Risks</li>
                      <li>Ecological Risks</li>
                    </ul>
                  </Col>
                  <Col md={1}></Col>{' '}
                  {/* Add Bootstrap spacing class here, e.g., "mb-3" */}
                  <Col md={5} lg={5} sm={6} className="card-sustain">
                    <h3 className="text-center">
                      <strong>Social Risks</strong>
                    </h3>
                    <ul className="mt-3">
                      <li>Employee Welfare Risks</li>
                      <li>Employee Health and Well-Being Risks</li>
                      <li>Discrepancies in Employee Compensations</li>
                      <li>
                        Risks related to Talent Availability and Acquisition
                      </li>
                      <li>Customer Safety and Welfare Risks</li>
                      <li>Transitions in Customer Behaviour</li>
                      <li>Risks related to Brand Perception</li>
                      <li>Data Protection and Privacy Risks</li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        <CarauselPage
          heading1="Data Collection"
          heading2="Report Generation"
          heading3="Consulting"
          sliderOneDescp="Easy inputs in the form of emission factors that would depict a very transparent set of inputs used for calculating your carbon footprint across the three scopes under consideration."
          sliderTwoDescp="Detailed reports clearly portraying all the computations involved, in alignment with the TCFD directives."
          sliderThreeDescp="Reach out to experts of your domain who are readily available for your consulting needs anytime."
        />
      </Container>
    </div>
  )
}

export default SustainabilityPage
