import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import CarauselPage from '../Services/Carousal';
import AcImage from '../../video/academy1.mp4';
import AcImage1 from '../../video/certificate1.mp4';
import './academypage.css';

const AcademyPageHere = () => {
    return (
        <>
            <div className='academybanerSection'>
                <div className='banertextgroup banneroverlay'>
                    <h1>Business Responsibilty & Sustainability Reporting (BRSR) Professional Certification Course</h1>
                </div>
            </div>
            <Container>
                <Row className='serve d-flex align-items-center'>
                    <Col lg={6} md={12}>
                        <h3 className='mb-4'>Unlock the Power of Responsible Business Practices with our Comprehensive 30-Hours Course </h3>
                        <p>At the RIU.ai Academy, we understand that the future of business lies in the hands of responsible and sustainable practices. As the global business landscape evolves, so does the need for professionals who are well-versed in Business Responsibility & Sustainability Reporting (BRSR). Our BRSR Professional Certification Course is designed to equip individuals, from novices to seasoned professionals, with the knowledge and skills needed to navigate the intricate world of corporate responsibility and sustainability reporting.</p>
                    </Col>
                    <Col lg={6} md={12}>
                        <div>
                            <video className='w-100' autoPlay loop muted>
                                <source src={AcImage} type="video/mp4" />
                            </video>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col lg={6} md={12}>
                        <div>
                            <video className='w-100' autoPlay loop muted>
                                <source src={AcImage1} type="video/mp4" />
                            </video>
                        </div>
                    </Col>
                    <Col lg={6} md={12}>
                        <h3 className='mb-3'>Why choose Our BRSR Professional Certification Course?</h3>
                        <h5>1. Comprehensive Curriculum:</h5>
                        <p>Our 30-hour course is meticulously crafted to cover every facet of Business Responsibility & Sustainability Reporting. From understanding the fundamentals to mastering advanced reporting techniques, you will embark on a journey that leaves no stone unturned.</p>
                        <h5>2. Suitable for All Levels:</h5>
                        <p>Whether you are just starting your career or you are a seasoned professional looking to enhance your skills, our course caters to individuals at all levels. The content is structured to progressively challenge and elevate your understanding, ensuring a rewarding learning experience for everyone.</p>
                    </Col>
                    <Row>
                        <Col>
                            <div className='mt-4'>
                                <h5>3. Expert Instructors:</h5>
                                <p>Learn from industry experts and thought leaders who bring real-world experience to the virtual classroom. Our instructors are dedicated to providing you with practical insights that bridge the gap between theory and application.</p>
                                <h5> 4. Interactive Learning:</h5>
                                <p>Engage in dynamic discussions, case studies, and hands-on exercises that simulate real-world scenarios. Our interactive approach ensures that you not only grasp theoretical concepts but also develop the practical skills necessary for success in the field.</p>
                                <h5>5 .Stay Updated with Industry Trends:</h5>
                                <p>Sustainability reporting is a dynamic field, and our course is regularly updated to reflect the latest trends, standards, and best practices. Be at the forefront of industry changes and position yourself as a leader in responsible business practices.</p>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <h1 className='mt-5 mb-3 text-center'>Course Highlights</h1>
                        <CarauselPage
                            heading1="Introduction to BRSR"
                            heading2="Data Collection and Analysis"
                            heading3="Stakeholder Engagement"
                            heading4="Ethical Considerations"
                            sliderOneDescp="Gain a comprehensive understanding of the principles and frameworks that govern Business Responsibility & Sustainability Reporting."
                            sliderTwoDescp=" Master the art of collecting, analyzing, and interpreting data to create meaningful and impactful sustainability reports."
                            sliderThreeDescp="Understand the importance of engaging with stakeholders and learn strategies to build meaningful relationships that contribute to your organization's success."
                            sliderFourDescp="Delve into the ethical aspects of business responsibility and sustainability, ensuring that your reporting aligns with the highest standards of integrity."
                        />
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <h1 className='mt-3 text-center'>Transform Your Career Today</h1>
                        <p>Elevate your career by enrolling in our BRSR Professional Certification Course. Whether you're a business professional, ESG practitioner, or someone passionate about making a positive impact, this course will equip you with the tools and knowledge needed to drive responsible and sustainable practices within your organisation.</p>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        </>
    )
}
export default AcademyPageHere;