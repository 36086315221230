import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './projectPage.css';
// import Image31 from '../../img/allneedAi.jpg';
const FramesPage = () => {
    return (
        <>
            <div className='esgframefbanerSection'>
                <video autoPlay muted loop className='video-background'>
                    <source src="https://riu-bucket.s3.ap-south-1.amazonaws.com/f5.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className='banertextgroup banneroverlay words_back'>
                    <h1>ESG Reporting Frameworks</h1>
                </div>
            </div>
            <Container>
                <div>
                    <Row className='serve'>
                        <Col>
                            <div className='First-para'>
                                <h5 className='mb-4'>ECOSYSTEM</h5>
                                <h6><strong>Stage-Wise Approach for ESG Reporting</strong></h6>
                                <p>
                                    <ul>
                                        <li>Align with stakeholder and establish expectations via materiality assessment</li>
                                        <li> Assess if current practices are sufficient to gather data required to address the expectations established</li>
                                        <li>Determine controls to assign responsibilities to concerned parties for efficient data sharing</li>
                                        <li> Ensure seamless continuity in the controls established so that any influx of new data is automatically updated for reporting</li>
                                        <li> Benchmark the reports generated periodically against your performances in the esg reporting frameworks</li>
                                    </ul>
                                </p>
                                <h6><strong>ESG Reporting Frameworks</strong></h6>
                                <div className='Anchor_12'>
                                    <ul>
                                        <li>
                                            <a href='https://www.globalreporting.org/' target='_blank' rel='noopener  noreferrer'>Global Reporting Initiative (GRI)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.globalreporting.org/media/mlkjpn1i/gri-sasb-joint-publication-april-2021.pdf' target='_blank' rel='noopener noreferrer'>Sustainable Accounting Standards Board (SASB)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.gresb.com/nl-en/' target='_blank' rel='noopener noreferrer'>GRESB</a>
                                        </li>
                                        <li>
                                            <a href='https://www.cdp.net/en' target='_blank' rel='noopener noreferrer'>Carbon Disclosure Project (CDP)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.bcorporation.net/en-us/certification/' target='_blank' rel='noopener noreferrer'>B Corporation (BCorp)</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className='model_swction_tab1 p-0'>
                            <div className='banertextgroup banneroverlays'>
                                <h2><a href='/reporting'>ESG STRATEGY</a></h2>
                            </div>
                        </Col>
                        <Col md={4} className='model_swction_tab2 p-0'>
                            <div className='banertextgroup banneroverlays'>
                                <h2><a href='/supplychain'>SUPPLY CHAIN</a></h2>
                            </div>
                        </Col>
                        <Col md={4} className='model_swction_tab3 p-0'>
                            <div className='banertextgroup banneroverlays'>
                                <h2><a href='/footprint'>CARBON FOOTPRINT</a></h2>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
export default FramesPage;