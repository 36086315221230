import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Middle2 = () => {
    return (
        <div className='Slide2'>
            <video autoPlay muted loop className='video-background'>
                <source src="https://riu-bucket.s3.ap-south-1.amazonaws.com/f4.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='Slide2_back'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <div className='W'>
                                <h2>TECHNOLOGY TO RADICALLY SIMPLIFY YOUR</h2>
                            </div>
                        </Col>
                        <Col>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='left'>
                                    <h5 className='mb-3'>Simplifying ESG compliance for companies and supply chains</h5>
                                    <h3>One Platform For All Your ESG Reporting And Intelligence Needs</h3>
                                    <br />
                                    <div className='report-section'>
                                        <h4>ESG Reporting</h4>
                                        <p>Generate multiple reports across major global frameworks including BRSR, GRI, CSRD, SASB, etc. on just a single click!</p>
                                        <hr style={{ color: 'white', opacity: 1, border: "1.5px solid" }} />
                                    </div>
                                    <div className='confidence-section'>
                                        <h4>Stakeholder confidence</h4>
                                        <p>Ensure the maximum transparency of your data and reports through comprehensive audit trails, to help you garner unparalleled stakeholder confidence</p>
                                        <hr style={{ color: 'white', opacity: 1, border: "1.5px solid" }} />
                                    </div>
                                    <div className='intelligence-section'>
                                        <h4>Market Intelligence</h4>
                                        <p>The system allows you to stay productive and establish yourself as a sustainability leader by providing you granular benchmarking intelligence on all your KPIs against your sectoral peers</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Middle2;
