import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Image28 from '../../img/blogpic.png'
import { NavLink } from 'react-router-dom'
const Middle3 = () => {
  return (
    <>
      <div className="Slide3">
        <Container>
          <h4 className="text-center mb-2">
            <strong>OUR USP</strong>
          </h4>
          <h3 className="text-center  mb-5">
            <strong>Reimagining the future of ESG Analytics</strong>
          </h3>
          <Row>
            <Col lg={4} md={6} sm={6} className="mb-2">
              <Card className="card-home">
                <Card.Body>
                  <Card.Title className="mb-3 text-center">
                    <strong>Performance Benchmarking</strong>
                  </Card.Title>
                  <Card.Text style={{ textAlign: 'justify' }}>
                    Through sectoral intelligence and your own trend analysis.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} sm={6} className="mb-2">
              <Card className="card-home">
                <Card.Body>
                  <Card.Title className="mb-3 text-center">
                    <strong>Eliminate errors</strong>
                  </Card.Title>
                  <Card.Text style={{ textAlign: 'justify' }}>
                    By cross-checking if your values are way different from
                    sectoral averages and finetune parameters.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <Card className="card-home">
                <Card.Body>
                  <Card.Title className="mb-3 text-center">
                    <strong>Optimisation Strategy</strong>
                  </Card.Title>
                  <Card.Text style={{ textAlign: 'justify' }}>
                    Using market intelligence and your own trend analysis, the
                    system also curates an “Optimisation Strategy” to enable
                    optimisation of ESG factors thereby establishing you as a
                    sustainability leader.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5">
              <div className="align-items-center justify-content-between">
                <h4 className="text-center mt-4">
                  <strong>Blog</strong>
                </h4>
                <h3 className="text-center mb-4">
                  <strong>BLOG WE HAVE DISCOVER</strong>
                </h3>
              </div>
              <Row>
                <Col md={4}></Col>
                <Col md={4}>
                  <img className="text-center w-100" src={Image28} alt="" />
                  <div className="align-items-center">
                    Conceptualizing ESG Integration in Supply Chain for long
                    term business sustainability
                  </div>
                  <div className="mt-3 text-end">
                    <NavLink to="/blog" className="button-block">
                      View All →
                    </NavLink>
                  </div>
                </Col>
                <Col md={4}></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Middle3
