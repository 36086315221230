import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./CSS/global.css";
import "./CSS/responsive.css";
import HomePage from "./Pages/Home/Index";
import AboutPage from "./Pages/About/Index";
import ContactPage from "./Pages/Contact/Index";
import Navbars from "./Pages/Components/Navbars";
import Footer from "./Pages/Components/Footer/Footer";
import FooterContact from "./Pages/Components/Footer/FooterContact";
import EsgReportingPage from "./Pages/Services/Esgr";
import SupplyChainPage from "./Pages/Services/Supply";
import CarbonFootPage from "./Pages/Services/CarbonFoot";
import FramesPage from "./Pages/Projects/EsgFreame";
import CarbonffPage from "./Pages/Projects/Carbonff";
import PrivacyPage from "./Pages/PrivacyP";
import TermsCondition from "./Pages/TermsC";
import CustomerSupport from "./Pages/CustomerS";
import CancellationRefund from "./Pages/CancellationR";
import BlogPageSection from "./Pages/Blog/BlogPage";
import BlogDetailPage from "./Pages/Blog/BlogDetailPage";
import SustainabilityPage from "./Pages/Projects/Sustainability";
import AcademyPageHere from "./Pages/Academy/AcademyPage";
import AnnualFiligs from "./Pages/annual-filings/annual-filings";
import RequestDemo from "./Pages/RequestDemo/RequestDemo";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const isContactPage = location.pathname === "/contact";

  return (
    <div className="App">
      <Navbars />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/request-demo" element={<RequestDemo />} />
        <Route exact path="/reporting" element={<EsgReportingPage />} />
        <Route exact path="/supplychain" element={<SupplyChainPage />} />
        <Route exact path="/footprint" element={<CarbonFootPage />} />
        <Route exact path="/esg-framework" element={<FramesPage />} />
        <Route exact path="/esg-footprint" element={<CarbonffPage />} />
        <Route exact path="/privacy-p" element={<PrivacyPage />} />
        <Route exact path="/terms-c" element={<TermsCondition />} />
        <Route exact path="/customerP" element={<CustomerSupport />} />
        <Route exact path="/cancellation" element={<CancellationRefund />} />
        <Route exact path="/blog" element={<BlogPageSection />} />
        <Route exact path="/blog-detail" element={<BlogDetailPage />} />
        <Route exact path="/sustain" element={<SustainabilityPage />} />
        <Route exact path="/academy" element={<AcademyPageHere />} />
        <Route exact path="/annual-filigs" element={<AnnualFiligs />} />
      </Routes>

      {/* Conditionally render FooterContact based on the route */}
      {!isContactPage && <FooterContact />}
      <Footer />
    </div>
  );
}

export default App;
