import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
// import Image42 from '../../img/whitepage.png';
const CarauselPage = ({
  sliderOneDescp,
  sliderTwoDescp,
  sliderThreeDescp,
  sliderFourDescp,
  heading1,
  heading2,
  heading3,
  heading4,
}) => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Carousel data-bs-theme="dark" interval={1000}>
              <Carousel.Item>
                <Carousel.Caption>
                  <h5>
                    <strong>{heading1}</strong>
                  </h5>
                  <p style={{ textAlign: 'center' }}>{sliderOneDescp}</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Carousel.Caption>
                  <h5>
                    <strong>{heading2}</strong>
                  </h5>
                  <p style={{ textAlign: 'center' }}>{sliderTwoDescp}</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Carousel.Caption>
                  <h5>
                    <strong>{heading3}</strong>
                  </h5>
                  <p style={{ textAlign: 'center' }}>{sliderThreeDescp}</p>
                </Carousel.Caption>
              </Carousel.Item>
              {heading4 ? (
                <Carousel.Item>
                  <Carousel.Caption>
                    <h5>
                      <strong>{heading4}</strong>
                    </h5>
                    <p style={{ textAlign: 'center' }}>{sliderFourDescp}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ) : (
                ''
              )}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default CarauselPage
