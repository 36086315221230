import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const FooterContact = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="p-0" md={12}>
            <div className="learn_more ">
              <div className="d-flex align-items-center justify-content-center gap-5">
                <div>
                  <h2 className="m-0">Have any Question in mind?</h2>
                </div>
                <div className="con-Foot">
                  <NavLink className="button-block" as={NavLink} to="/contact">
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FooterContact
