import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const CancellationRefund = () => {
  return (
    <>
      <div className="cancelbanerSection">
        <div className="banertextgroup banneroverlay">
          <h1>Cancellation & Refund</h1>
        </div>
      </div>
      <Container>
        <Row className="Privacy-12">
          <Col>
            <h1 className="custom-title mb-3">Refund and Returns Policy</h1>
            <h2>Overview</h2>
            <p className="move-para">
              Our refund and returns policy has a duration of 30 days. If 30
              days have elapsed since your purchase, we regret that we cannot
              offer you a full refund or exchange.
            </p>
            <p className="move-para">
              For a return to be eligible, the item must be unused and in its
              original condition and packaging
            </p>
            <p className="move-para">
              Please note that certain types of goods are exempt from being
              returned, including perishable items like food, flowers,
              newspapers, or magazines. Additionally, we cannot accept returns
              for intimate or sanitary goods, hazardous materials, or flammable
              liquids or gases.
            </p>
            <p className="move-para">
              The following items are also non-returnable:
            </p>
            <ul className="move-para">
              <li>
                <p>Gift cards</p>
              </li>
              <li>
                <p>Downloadable software products</p>
              </li>
              <li>
                <p>Some health and personal care items</p>
              </li>
            </ul>
            <p className="move-para">
              To initiate a return, you must provide a receipt or proof of
              purchase.
            </p>
            <p className="move-para">
              Please refrain from returning your purchase directly to the
              manufacturer.
            </p>
            <p className="move-para">
              Partial refunds may be granted in specific situations, such as:
            </p>
            <ul className="move-para">
              <li>
                <p>Books with evident signs of use</p>
              </li>
              <li>
                <p>
                  Opened CD, DVD, VHS tape, software, video game, cassette tape,
                  or vinyl record
                </p>
              </li>
              <li>
                <p>
                  Items not in their original condition, damaged, or with
                  missing parts due to reasons unrelated to our error
                </p>
              </li>
              <li>
                <p>Returns made more than 30 days after delivery</p>
              </li>
            </ul>
            <h2>Refunds</h2>
            <p className="move-para">
              Upon receipt and inspection of your return, we will send you an
              email notification confirming the arrival of your returned item.
              Subsequently, we will inform you about the approval or rejection
              of your refund.
            </p>
            <p className="move-para">
              If approved, the refund will be processed, and the credit will be
              automatically applied to your original method of payment within a
              specified timeframe.
            </p>
            <p className="move-para">Late or Missing Refunds</p>
            <p className="move-para">
              Should you not have received a refund yet, kindly check your bank
              account once more. If necessary, contact your credit card company,
              as there may be a delay before the refund is officially processed.
              Similarly, get in touch with your bank, as some processing time is
              typical before a refund is posted.
            </p>
            <p className="move-para">
              Should you have exhausted these steps and still not received your
              refund, please reach out to us at contact@riu.ai.
            </p>
            <h2>Sale Items</h2>
            <p className="move-para">
              Please note that only regular-priced items are eligible for
              refunds. Unfortunately, sale items cannot be refunded.
            </p>
            <h2>Exchanges</h2>
            <p className="move-para">
              We offer exchanges only for defective or damaged items. To
              initiate an exchange for the same item, please email us at
              contact@riu.ai and mail your item to: Bangalore, India.
            </p>
            <h2>Gifts</h2>
            <p className="move-para">
              If the item was marked as a gift during purchase and directly
              shipped to you, you will receive a gift credit for the return’s
              value. Upon receipt of the returned item, a gift certificate will
              be mailed to you.
            </p>
            <p className="move-para">
              If the item was not marked as a gift during purchase, or if the
              gift giver had the order shipped to themselves before gifting it
              to you, we will issue a refund to the gift giver, who will be
              notified of the return.
            </p>
            <h2>Shipping Returns</h2>
            <p className="move-para">
              To return your product, please mail it to: Bangalore, India.
            </p>
            <p className="move-para">
              You are responsible for covering your own shipping costs for
              returning the item. Shipping costs are non-refundable, and if a
              refund is issued, the return shipping cost will be deducted from
              it.
            </p>
            <p className="move-para">
              The time it takes for your exchanged product to reach you may vary
              based on your location. For higher-value items, consider using a
              trackable shipping service or purchasing shipping insurance.
              Please note that we cannot guarantee receipt of your returned
              item.
            </p>
            <h2>Need Assistance?</h2>
            <p className="move-para">
              For any questions concerning refunds and returns, please reach out
              to us at contact@riu.ai. We’re here to help!
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default CancellationRefund
