import React from "react";

const RequestDemoBannerSection = () => {
  return (
    <>
      <div className="contactbanerSection">
        <div className="banertextgroup banneroverlay ">
          <h1>
            <span>Experience RIU</span>
            <br />
            Request a Demo
          </h1>
        </div>
      </div>
    </>
  );
};

export default RequestDemoBannerSection;
