import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image46 from '../../img/blogpic.png'
import './BlogPage.css'

const BlogDetailPage = () => {
  return (
    <div>
      <div className="blogpagebanerSection">
        <div className="banertextgroup banneroverlay">
          <h2>
            Conceptualizing ESG Integration in Supply Chain for long term
            business sustainability
          </h2>
        </div>
      </div>
      <Container>
        <Row className="my-5">
          <Col md={2}>
            <div className="blog-detail-section">
              <h4>WRITTEN BY :</h4>
              <div className="inline">
                <svg
                  className="movement"
                  width="12px"
                  height="12px"
                  viewBox="0 0 448 512"
                >
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>
                <p className="mt-1">RIU</p>
              </div>
              <hr></hr>
              <h4>CATEGORY :</h4>
              <div className="inline">
                <svg
                  className="movement"
                  width="12px"
                  height="12px"
                  viewBox="0 0 576 512"
                >
                  <path d="M88.7 223.8L0 375.8V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H416c35.3 0 64 28.7 64 64v32H144c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224H544c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480H32c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z" />
                </svg>
                <p className="mt-1">ESG</p>
              </div>
              <hr></hr>
              <h4>POSTED ON :</h4>
              <div className="inline">
                <svg
                  className="movement"
                  width="12px"
                  height="12px"
                  viewBox="0 0 448 512"
                >
                  <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                </svg>
                <p className="mt-1">DECEMBER 29, 2022</p>
              </div>
              {/* <hr></hr> */}
              {/* <h4>SHARE THIS :</h4>
                            <div className='d-flex align-items-center gap-2'>
                                <a href="http://"><svg width='20px' height='20px' viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg></a>
                                <a href="http://"><svg width='20px' height='20px' viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg></a>
                                <a href="http://"><svg width='20px' height='20px' viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg></a>
                            </div> */}
            </div>
          </Col>
          <Col md={2}></Col>
          <Col md={8}>
            <img className="w-100" src={Image46} alt="" />
            <div className="blog-detail-text mt-4">
              <p>
                At an uber level, ESG focuses on 3 major areas of business for
                long term sustainability: Environmental, Social, and Governance.
                These issues pertaining to these areas affect commercial
                enterprise’s inner make-up as well as external effects. Even as
                these are non-economic elements, they are directly connected to
                company’s growth and risk factors. These issues not only concern
                a company’s inner dynamics and its exterior footprint rather
                also incorporate how companies organize business and how that
                business affects the world.
              </p>
              <p>
                Environmental issues encompass everything that has to do with a
                corporation’s impact at the environment and the sustainability
                of its operations. Emissions, climate change, and a
                manufacturer’s overall carbon footprint may be addressed via
                ESG. Social issues cover how an organization’s activities –
                everything from hiring employees, to sourcing goods – impact
                both internal stakeholders and the global community. At the
                highest level, social issues can incorporate workplace
                diversity, in addition to issues like safe working conditions,
                fair compensation, employee and consumer data privacy,
                whistleblower protections, forced or child labor, and conflict
                minerals. Governance largely relates to a manufacturer’s
                internal governance structure, including how rights and
                responsibilities are distributed within an organization. How an
                organization governs itself, meets stakeholder needs, discloses
                information, enforces company policies and standards, and
                complies with the law are just a few ways this tie into ESG
                overall.
              </p>
              <p>
                Consideration must be given to indirect consequences that occur
                upstream or downstream, which include greenhouse gas emissions
                from product use or a company’s supply chain activities,
                sometimes referred to as Scope 3 emissions. Digging deeper,
                manufacturers will discover supply chain ESG more relevant,
                applicable and beneficial to focus on. For many industry
                verticals, the majority of a producer’s ESG footprint is hiding
                deep within their supply chain, as a result of complex upstream
                & downstream operations. Therefore, creating programs and
                solutions directly tied to the supply chain can result in the
                most impactful outcomes.
              </p>
              <p>
                Additionally, focusing on supply chain ESG offers a manufacturer
                greater insight into where and how materials are procured. For
                example, a supply chain ESG framework lets in a producer to make
                sure materials aren’t manufactured with child labor, does not
                utilize conflict minerals, and do not have negative impact on
                environmental sustainability. In that way, effective supply
                chain ESG interventions allow a manufacturer to both positively
                affect the world around them as well as mitigate risk by
                anticipating where future supply chain shortages or redesigns
                may occur.
              </p>
              <p>
                Now, with the upcoming EU Corporate Sustainability Reporting
                Directive (CSRD), annual ESG reporting turns into a requirement
                for all large corporations and all business groups listed on
                regulated markets in the EU. It will also become a requirement
                for non-EU entities with significant EU operations. Put simply,
                the CSRD requires in-scope business houses to publish annual
                reports on their ESG activities. This is an extension of the
                scope and reporting requirements that exist in the EU
                Non-Financial Reporting Directive — a framework that mandates
                “good sized” public-interest entities to file on their
                sustainability performance. The CSRD and the ESRS updates are
                examples of a bigger global trend toward ESG reporting. With
                investors calling for reliable, comparable, and relevant ESG
                information from companies, more guidelines and standards are
                being created to meet these regulatory requirements.
              </p>
              <p>
                As supply chains have become a primary growth driver and key
                activator for environmental, social and governance (ESG)
                initiatives, they have simultaneously gained importance in the
                board room at many corporations. As a result, visibility into
                supply chain actions and outcomes has catapulted to the top of
                many corporate Wishlist — but many business leaders become
                frustrated when their operations and technologies don’t deliver.
                Still, professionals say, better visibility into corporate
                supply chains can be achieved, but only if companies are willing
                to think about their sustainable supply chain initiatives in a
                more innovative way.
              </p>
              <p>
                When it comes to trying to raise the visibility of supply chain
                practices and results, many corporate leaders have run into an
                unfortunate reality: the difficulty of amassing and mingling
                statistics that lives in disparate systems. This problem is only
                increasing as corporations are beginning to scale up the types
                of information and records that they collect. To take a more
                impregnable grasp on their supply chain, many businesses are
                looking to catalog not only emissions from scope 1 (directly
                owned by the company) and scope 2 (indirect use of energy the
                company purchases), but increasingly but also the scope 3
                emissions that result both up and down the company’s value chain
                as well. Indeed, the more a company’s data collection scope
                expands, the more complex the visibility question becomes.
              </p>
              <p>
                Currently, most supply chain networks are “not digitally
                integrated in their true sense” because they operate in multiple
                stages. Data is processed by one agency that controls its
                segment of the supply chain ecosystem, then it is transmitted
                with the intention to be consumed or processed by other
                organizations. The future of managing ESG aspects of the supply
                chain lies in combining, integrating, and automating these steps
                as prima facies at juncture we can fairly conclude that It’ll
                take lot of serious efforts and time to ultimately get to an
                nearly autonomous state.
              </p>
              <p>
                Many sustainability frameworks are available, and increased
                regulatory attention continues to add greater complexity.
                Increased standardization can make supply chain data more
                actionable, and auditable, potentially lowering a company’s risk
                profile. Leading companies are already pushing for data
                standardization among common supply chain suppliers. Many supply
                chain-centric software providers have arisen in recent years to
                try and compile and display all of these data sources, however,
                currently, there is not a leader that has captured a substantial
                share of the market. As both public and regulatory attention in
                the space continue, we expect that visualization into supply
                chain processes and data will become even more significant, and
                leading organizations will continue to invest resources and
                personnel to get their supply chain data in order.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BlogDetailPage
