import React from 'react'
import { Container } from 'react-bootstrap';

const AboutPageBannerSection = () => {
    return (
        <>
            <Container fluid className='p-0'>
                <div className='aboutbanerSection'>
                    <div className='banertextgroup banneroverlay'>
                        <h1>About Us</h1>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default AboutPageBannerSection;
