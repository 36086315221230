import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import Image30 from '../../img/footprint1.png';
import BGimage from '../../video/cfp2.mp4'
import CarauselPage from './Carousal'

const CarbonFootPage = () => {
  return (
    <>
      <div className="carbonfootbanerSection">
        <video autoPlay muted loop playsInline className="video-background">
          <source
            src="https://riu-bucket.s3.ap-south-1.amazonaws.com/11.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="banertextgroup banneroverlay words_back">
          <h1>Carbon Footprint Strategy</h1>
        </div>
      </div>
      <Container>
        <div>
          <Row className="serve">
            <Col lg={6} md={12}>
              <div className="First-para">
                <h5>ECOSYSTEM</h5>
                <h2 className="mb-3">Your Footprint</h2>
                <p style={{ textAlign: 'justify' }}>
                  Through the Carbon Footprint Strategy offering, we aid you in
                  calculating, reporting, and improving your carbon footprint.
                  The entire disclosure cycle would be based to the carbon
                  footprint exposure metrics as prescribed by the Task-Force on
                  Climate Related Financial Disclosures (TCFD).
                </p>
                <p style={{ textAlign: 'justify' }}>
                  We cover the full spectrum of emission factors and track GHG
                  emissions falling under the 3 scopes as directed by the
                  principles of TCFD. Our offering is customisable for the needs
                  of businesses operating across industries, right from small to
                  medium sized entities, to large enterprises and global supply
                  chains.
                </p>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div>
                <video className="vedio-width" autoPlay loop muted>
                  <source src={BGimage} type="video/mp4" />
                </video>
              </div>
            </Col>
          </Row>
          <div style={{ padding: '0px 0' }}>
            <div className="text-center mb-5"></div>
            <CarauselPage
              heading1="Data Collection"
              heading2="Report Generation"
              heading3="Consulting"
              sliderOneDescp="Easy inputs in the form of emission factors that would depict a very transparent set of inputs used for calculating your carbon footprint across the three scopes under consideration."
              sliderTwoDescp="Detailed reports clearly portraying all the computations involved, in alignment with the TCFD directives."
              sliderThreeDescp="Reach out to experts of your domain who are readily available for your consulting needs anytime."
            />
          </div>
        </div>
      </Container>
    </>
  )
}
export default CarbonFootPage
