import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Image8 from "../../img/logo.png";
import { Dropdown } from "react-bootstrap";
import "./Navbars.css"; // Import the CSS file for styling

function Navbars() {
  const location = useLocation();

  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isFrameworkOpen, setIsFrameworkOpen] = useState(false);

  const handleSolutionsMouseOver = () => {
    setIsSolutionsOpen(true);
  };

  const handleSolutionsMouseLeave = () => {
    setIsSolutionsOpen(false);
  };

  const handleFrameworkMouseOver = () => {
    setIsFrameworkOpen(true);
  };

  const handleFrameworkMouseLeave = () => {
    setIsFrameworkOpen(false);
  };

  return (
    <>
      {["xxl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          style={{ position: "absolute" }}
          className="w-100"
        >
          <Container>
            <Navbar.Brand as={NavLink} to="/">
              <img style={{ width: 200 }} src={Image8} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  RIU
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 gap-3">
                  <Nav.Link
                    as={NavLink}
                    to="/"
                    exact
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/about"
                    className={location.pathname === "/about" ? "active" : ""}
                  >
                    About
                  </Nav.Link>
                  <Dropdown
                    className="drop"
                    onMouseOver={handleSolutionsMouseOver}
                    onMouseLeave={handleSolutionsMouseLeave}
                    show={isSolutionsOpen}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      Solutions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={NavLink}
                        to="/reporting"
                        className={
                          location.pathname === "/reporting" ? "active" : ""
                        }
                      >
                        ESG Reporting
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        to="/supplychain"
                        className={
                          location.pathname === "/supplychain" ? "active" : ""
                        }
                      >
                        Supply Chain Monitoring
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        to="/footprint"
                        className={
                          location.pathname === "/footprint" ? "active" : ""
                        }
                      >
                        Carbon Footprints
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown
                    className="drop"
                    onMouseOver={handleFrameworkMouseOver}
                    onMouseLeave={handleFrameworkMouseLeave}
                    show={isFrameworkOpen}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      Sustainability Framework
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={NavLink}
                        to="/esg-framework"
                        className={
                          location.pathname === "/esg-framework" ? "active" : ""
                        }
                      >
                        ESG Framework
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        to="/esg-footprint"
                        className={
                          location.pathname === "/esg-footprint" ? "active" : ""
                        }
                      >
                        Carbon Footprint Framework
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Nav.Link
                    as={NavLink}
                    to="/academy"
                    className={location.pathname === "/academy" ? "active" : ""}
                  >
                    Academy
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/blog"
                    className={location.pathname === "/blog" ? "active" : ""}
                  >
                    Blog
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/contact"
                    className={location.pathname === "/contact" ? "active" : ""}
                  >
                    Contact
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/request-demo"
                    className={
                      location.pathname === "/request-demo" ? "active" : ""
                    }
                  >
                    Request Demo
                  </Nav.Link>
                  {/* Add other Nav.Link components here */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navbars;
