import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
// import Image13 from '../../img/allneedAi.jpg';
const Middle1 = () => {
  return (
    <>
      <div style={{ background: 'white' }}>
        <Container>
          <Row className="align-items-center firstMiddleSection">
            <Col>
              <div className="middleLeftSection">
                <h4 className="text-center">
                  <strong>Why RIU</strong>
                </h4>
                <h1 className="pb-5 pt-3 text-center">
                  Let's Discuss Sustainability Impact!
                </h1>
                <Row className="mb-5">
                  <Col md={2}></Col>
                  <Col md={8}>
                    <div className="text-center">
                      <p style={{ textAlign: 'justify' }}>
                        Sustainability concerns are not restricted by size. We
                        enable everyone from retail links to global chains to
                        improve their ESG performance. We are a team of
                        dedicated technical and sustainability professionals
                        driven by the sole intention of extending ESG to the
                        nook and corners of global supply chains.
                      </p>
                      <p style={{ textAlign: 'justify' }}>
                        It take months for you to collect, compile, and report
                        sustainability data, which we enable you to accomplish
                        in hours while significantly reducing user inputs at
                        much lower costs. We also enable users to continuously
                        monitor sustainability performances across supply chains
                        and make timely interventions wherever necessary – Not
                        having to wait till the completion of reporting cycle.
                      </p>
                    </div>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <div className="d-flex justify-content-center mt-4">
                  <NavLink as={NavLink} to="/about" className="button-block">
                    Learn More
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Middle1
