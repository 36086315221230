import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image28 from '../../img/blogpic.png'
import './annual-filings.css'
const AnnualFiligs = () => {
  return (
    <>
      <div className="blogbanerSection">
        <div className="banertextgroup banneroverlay">
          <h1>Annual filings </h1>
        </div>
      </div>
    </>
  )
}
export default AnnualFiligs
