import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import AboutPageBannerSection from './AboutPageBannerSection.js';
import Middle1 from './Middle1';
import './aboutPage.css';

const AboutPage = () => {
    return (
        <>
            <Container fluid="fluid">
                <Row>
                    <Col className='p-0'>
                        <AboutPageBannerSection />
                        <Middle1 />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AboutPage;