// import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Image24 from '../../img/esgr123.avif';
import Image25 from '../../img/esgr2.png';
import './servicesPage.css';
import CarauselPage from './Carousal';

const EsgReportingPage = () => {
    return (
        <>
            <div className='esgrbanerSection'>
            <video autoPlay muted loop className='video-background'>
                    <source src="https://riu-bucket.s3.ap-south-1.amazonaws.com/f1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className='banertextgroup banneroverlay words_back'>
                    <h1>ESG Firm Strategy </h1>
                </div>
            </div>
            <Container>
                <div>
                    <Row className='serve d-flex align-items-center'>
                        <Col md={12} lg={6}>
                            <div className='First-para'>
                                <h5>ECOSYSTEM</h5>
                                <h2>Firm-Level Strategy</h2>
                                <p style={{ textAlign: 'justify' }}>ESG and non-financial reporting is creating a lot of buzz today and the ever-evolving regulatory norms and stakeholder expectations are proving to be intimidating to business owners. Companies are finding it hard to gather data, select frameworks, structure reports, and improve performance on the ESG front, due to the huge variations in the requirements and expectations.</p>
                                <p style={{ textAlign: 'justify' }}>
                                    Fret not, we are here to simplify ESG to your through our comprehensive offering – The ESG Firm Strategy, that takes care of all requirements, not just of your firm, but also those of your collaborators! This is because we identify that your ESG performance is a combined effort of internal as well as external partners of your business.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <img className="pic24" src={Image24} alt='' />
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center mt-4'>
                        <Col md={12} lg={6}>
                            <img className="pic24" src={Image25} alt='' />
                        </Col>
                        <Col md={12} lg={6}>
                            <div className='First-para'>
                                <h2>We completely assist you in</h2>
                                <ul>
                                    <li style={{ textAlign: 'justify' }}>Demystifying the ESG landscape</li>
                                    <li style={{ textAlign: 'justify' }}>Collecting and organising data, preparing reports, and improving your ESG score</li>
                                    <li style={{ textAlign: 'justify' }}>Aligning all concerned parties with the strategy, and</li>
                                    <li style={{ textAlign: 'justify' }}>Not just keeping up with the dynamic expectations, but also exceeding them</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ padding: "52px 0" }}>
                        <div className='text-center mb-5'>
                        </div>
                        <CarauselPage
                            heading1="Data Collection"
                            heading2="Report Generation"
                            heading3="Consulting"
                            sliderOneDescp="We make it very simple and objective for you to gather required information to address all aspects of ESG disclosures. You can also reuse necessary content where applicable with greater ease, so that repetitive need for fetching the same sets of information is eliminated."
                            sliderTwoDescp="Based the regulatory environment and business interests, we help you choose the right ESG framework including GRI, SASB, CBI, etc., to structure your reports that are clear, concise, and comprehensive to the stakeholders in understanding the risks and areas of improvement for your venture."
                            sliderThreeDescp="Reach out to experts of your domain who are readily available for your consulting needs anytime."
                        />
                    </div>
                </div>
            </Container>
        </>
    )
}
export default EsgReportingPage;