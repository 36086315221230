import React from 'react'

const ContactPageBannerSection = () => {
    return (
        <>
            <div className='contactbanerSection'>
                <div className='banertextgroup banneroverlay '>
                    <h1>Contact Us</h1>
                </div>
            </div>
        </>
    )
}

export default ContactPageBannerSection;