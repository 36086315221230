import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image28 from '../../img/blogpic.png'
import { NavLink } from 'react-router-dom'
import './BlogPage.css'
const BlogPageSection = () => {
  return (
    <>
      <div className="blogbanerSection">
        <div className="banertextgroup banneroverlay">
          <h1>Our Blogs </h1>
        </div>
      </div>
      <Container>
        <Row>
          <Col className="mt-5 text-center">
            <h2 className="mt-4">
              <strong>Blog</strong>
            </h2>
            <h5 className="my-4">BLOG WE HAVE DISCOVER</h5>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={4}></Col>
          <Col md={4}>
            <div className="blog_card">
              <img className="text-center w-100" src={Image28} alt="" />
              <div className="align-items-center">
                Conceptualizing ESG Integration in Supply Chain for long term
                business sustainability
              </div>
              <div className="mt-5 mb-3">
                <NavLink
                  as={NavLink}
                  to="/blog-detail"
                  className="button-block"
                >
                  Read More →{' '}
                </NavLink>
              </div>
            </div>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </>
  )
}
export default BlogPageSection
