import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import NumberCounter from 'number-counter';
import Image10 from '../../img/esg20.png'
// import 'bootstrap/dist/css/bootstrap.min.css'

const Middle1 = () => {
  return (
    <>
      <Container>
        <Row className="align-items-center firstMiddleSection">
          <Col>
            <div className="middleLeftSection">
              <h4 className="text-center">Why RIU</h4>
              <h1 className="pb-5 pt-3 text-center">
                Let's Discuss Sustainability Impact!
              </h1>
              <Row className="mb-5">
                <Col md={12}>
                  <div className="text-center">
                    <p style={{ textAlign: 'justify' }}>
                      Sustainability concerns are not restricted by size. We
                      enable everyone from retail links to global chains to
                      improve their ESG performance. We are a team of dedicated
                      technical and sustainability professionals driven by the
                      sole intention of extending ESG to the nook and corners of
                      global supply chains.
                    </p>
                    <p style={{ textAlign: 'justify' }}>
                      It take months for you to collect, compile, and report
                      sustainability data, which we enable you to accomplish in
                      hours while significantly reducing user inputs at much
                      lower costs. We also enable users to continuously monitor
                      sustainability performances across supply chains and make
                      timely interventions wherever necessary – Not having to
                      wait till the completion of reporting cycle.
                    </p>
                    <p style={{ textAlign: 'justify' }}>
                      Additionally, we ensure 100% data security and
                      transparency across value chains to eliminate instances of
                      greenwashing, reducing your penalty risks and enhancing
                      your stakeholder confidence.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row style={{ margin: '-2rem', marginRight: '3rem' }}>
                <Col md={2}></Col>
                <Col
                  md={12}
                  lg={4}
                  sm={6}
                  className="card-look"
                  style={{ margin: '2rem' }}
                >
                  <h3 className="text-center">
                    <strong>Our Vision</strong>
                  </h3>
                  <p className="mt-4" style={{ textAlign: 'justify' }}>
                    To become the global leader in value chain ESG management.
                  </p>
                </Col>
                <Col
                  md={12}
                  lg={4}
                  sm={6}
                  className="card-look"
                  style={{ margin: '2rem' }}
                >
                  <h3 className="text-center">
                    <strong>Our Mission</strong>
                  </h3>
                  <p className="mt-4" style={{ textAlign: 'justify' }}>
                    Our mission is to make ESG effortless, affordable, and
                    accessible to every player in the global value chain.
                  </p>
                </Col>
                <Col md={2}></Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="Nature_lover">
          <Col lg={6} md={12}>
            <div className="AboutM">
              <h5>ECOSYSTEM</h5>
              <h1>Our Story</h1>
              <p style={{ textAlign: 'justify' }}>
                We are a passionate team of technologists and ESG professionals
                solely driven by a vision to simplify ESG compliance for
                companies and supply chains.
              </p>
              <p style={{ textAlign: 'justify' }}>
                We are developing the world’s finest AI-enabled platform to
                automate ESG data collection across organisations and supply
                chains, enable market intelligence for all major KPIs of an
                organisation, and simplify stakeholder training through a
                specialised learning management system. In summary, we want to
                make ESG affordable and accessible to every player on your value
                chain.
              </p>
              <a className="button-block" href="/sustain">
                READ OUR STANDARDS OF BUSINESS HERE
              </a>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="">
              <img className="w-100" src={Image10} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Middle1
